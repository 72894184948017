import React, { FC } from 'react';

import '@rainbow-me/rainbowkit/styles.css';

import 'polyfill-object.fromentries';
import { RainbowKitProvider, getDefaultWallets } from '@rainbow-me/rainbowkit';
import { Web3ReactProvider } from '@web3-react/core';
import { AppProps } from 'next/app';
import Script from 'next/script';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { ReactNotifications } from 'react-notifications-component';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { chain, createClient, configureChains, WagmiConfig } from 'wagmi';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import Web3 from 'web3';

import { queryClient } from '@/api/index';
import 'react-notifications-component/dist/theme.css';

import '../styles/Button.css';
import '../styles/ContentEditable.css';
import '../styles/editorStyles.css';
import '../styles/globals.css';
import '../styles/Input.css';
import '../styles/ImageNode.css';
import '../styles/Placeholder.css';
import '../styles/ToolbarPlugin.css';
import '../styles/AnnotationLayer.css';

const getLibrary = (provider: any): Web3 => new Web3(provider);
const { chains, provider } = configureChains(
  [chain.mainnet, chain.polygon, chain.optimism, chain.arbitrum],
  [alchemyProvider({ alchemyId: process.env.NEXT_PUBLIC_ALCHEMY_API_KEY })]
);

const { connectors } = getDefaultWallets({
  appName: 'Fileverse',
  chains,
});

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
});

const MyApp: FC<AppProps> = ({ Component, pageProps }) => {
  return (
    <>
      <Script
        src="https://polyfill.io/v3/polyfill.min.js?features=IntersectionObserver"
        strategy="beforeInteractive"
      />
      <title>Fileverse</title>
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.NEXT_PUBLIC_SITE_KEY}
        scriptProps={{
          async: false, // optional, default to false,
          defer: false, // optional, default to false
          appendTo: 'body',
        }}
      >
        <QueryClientProvider client={queryClient}>
          <Web3ReactProvider getLibrary={getLibrary}>
            <ReactNotifications />
            <WagmiConfig client={wagmiClient}>
              <RainbowKitProvider chains={chains}>
                <Component {...pageProps} />
              </RainbowKitProvider>
            </WagmiConfig>
          </Web3ReactProvider>

          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </GoogleReCaptchaProvider>
    </>
  );
};

export default MyApp;
